import { Route, Switch } from 'react-router-dom';
import Homepage from '../Homepage/Homepage';
import { Background, Content } from './Root.styles';
import { useProgressiveImage } from '../../lib/progressiveImage';

import bg from '../../assets/media/bg.jpg';
import bgPlaceholder from '../../assets/media/bg-placeholder.jpg';

const Root = () => {
  const loaded = useProgressiveImage(bg);

  return (
    <>
      <Background
        backgroundImage={loaded || bgPlaceholder}
        blur={!loaded}
      ></Background>
      <Content>
        <Switch>
          <Route component={Homepage} path="/" />
        </Switch>
      </Content>
    </>
  );
};

export default Root;
