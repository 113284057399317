/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import logo from '../../assets/media/wingtips-logo.jpeg';

/* STYLES */
import {
  Container,
  WidthContainer,
  ImgContainer,
  Logo,
  TextContainer,
  Headline,
  Text,
  TextSmall,
  BreakContainer,
  Break,
  ButtonContainer,
  LinkElement,
  LinkElementDark,
  LinkText,
  SectionHeaderText,
} from './Homepage.styles';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../services/config';

const Homepage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          flex: '0 1 auto',
          width: '100%',
        }}
      >
        <Container>
          <WidthContainer>
            <ImgContainer>
              <Logo src={logo} />
            </ImgContainer>
            <TextContainer>
              <Headline>{t('welcome_to_wingtips')}</Headline>
              <Text>
                {t(
                  'a_communication_resource_for_red_bull_distribution_partners'
                )}
              </Text>
            </TextContainer>
          </WidthContainer>
          <BreakContainer>
            <TextSmall>{t('choose_a_way_to_log_in')}</TextSmall>
            <Break />
          </BreakContainer>

          <WidthContainer>
            <SectionHeaderText>
              {t('third_party_distributors')}
            </SectionHeaderText>
            {getConfig('REACT_APP_SHOW_PARTNER_ID') === 'true' && (
              <>
                <Text>
                  {t('distributors_and_wholesalers_partner_id_types')}
                </Text>
                <ButtonContainer>
                  <LinkElementDark
                    href={getConfig('REACT_APP_PARTNER_ID_SSO_URL')}
                  >
                    <LinkText>
                      {t('distributors_and_wholesalers_partner_id')}
                    </LinkText>
                  </LinkElementDark>
                </ButtonContainer>
              </>
            )}
            {getConfig('REACT_APP_SHOW_OKTA') === 'true' && (
              <>
                <Text>{t('distributors_and_wholesalers_okta_types')}</Text>
                <ButtonContainer>
                  <LinkElementDark href={getConfig('REACT_APP_OKTA_SSO_URL')}>
                    <LinkText>
                      {t('distributors_and_wholesalers_okta')}
                    </LinkText>
                  </LinkElementDark>
                </ButtonContainer>
              </>
            )}
          </WidthContainer>
          <WidthContainer style={{ paddingTop: '40px', paddingBottom: '20px' }}>
            <SectionHeaderText>{t('red_bull_employees')}</SectionHeaderText>
            <Text>{t('red_bull_employee_types')}</Text>
            <ButtonContainer>
              <LinkElement href={getConfig('REACT_APP_INTERNAL_SSO_URL')}>
                <LinkText>{t('red_bull_employees')}</LinkText>
              </LinkElement>
            </ButtonContainer>
          </WidthContainer>
        </Container>
      </div>
    </>
  );
};

export default Homepage;
