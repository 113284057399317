export const resources = {
  en: {
    translation: {
      welcome_to_wingtips: 'WELCOME TO WINGTIPS!',
      a_communication_resource_for_red_bull_distribution_partners:
        'A communication resource for Red Bull Distribution Partners.',
      choose_a_way_to_log_in: 'choose a way to log in:',
      third_party_distributors: '3rd Party Distributors',
      distributors_and_wholesalers_partner_id_types: '(United States)',
      distributors_and_wholesalers_partner_id: 'US Distributors & Wholesalers',
      distributors_and_wholesalers_okta_types: '(Rest of the World)',
      distributors_and_wholesalers_okta: 'Distributors & Wholesalers',
      red_bull_employees: 'Red Bull Employees',
      red_bull_employee_types: '(RBNA, Agency, RBDC, RBCD, etc.)',
    },
  },
};
