import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import Root from './components/Root/Root';
import * as serviceWorker from './serviceWorker';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './locales/resources';
import LanguageDetector from 'i18next-browser-languagedetector';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    resources,
    // ns: ["translation"],
    debug: true,
    detection: {
      order: ['navigator', 'querystring', 'localStorage'],
      lookupQuerystring: 'ui_locales',
      lookupLocalStorage: 'ui_locales',
      convertDetectedLanguage: lng => lng,
    },
  });

if (window.REACT_APP_TRANSLATION_KEYS_EN) {
  i18n.addResourceBundle(
    'en',
    'translation',
    window.REACT_APP_TRANSLATION_KEYS_EN
  );
}

i18n.changeLanguage(); // this runs to detect the language the user has set on their browser

render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
