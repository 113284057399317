import styled from 'styled-components';

export const Background = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-size: cover;
  ${props => props.blur && 'filter: blur(8px);'}
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
