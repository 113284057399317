export const $TabletVWidth = '768';
export const $TabletHWidth = '992';
export const $LaptopWidth = '1200';
export const $DesktopWidth = '1680';
export const $MobileWidth = '630';

export const $Mobile = `max-width: ${$MobileWidth}px`;
export const $TabletV = `max-width: ${$TabletVWidth}px`;
export const $TabletH = `max-width:  ${$TabletHWidth}px`;
export const $Laptop = `max-width:  ${$LaptopWidth}px`;
export const $Desktop = `max-width:  ${$DesktopWidth}px`;
