import styled from 'styled-components';
import {
  $Desktop,
  $Laptop,
  $Mobile,
  $TabletH,
  $TabletV,
} from '../../lib/breakpoints';

export const Background = styled('img')`
  width: 100%;
  object-fit: cover;
  z-index: -99;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('./assets/media/bg.jpg');
  background-size: cover;
`;

export const Container = styled('div')`
  position: relative;
  left: 0;
  right: 0;
  background-color: white;
  margin: 0 auto;
  width: 600px;
  padding: 2.375em;

  @media (${$Laptop}) {
    width: 600px;
    padding: 2em;
  }

  @media (${$TabletV}) {
    width: 80%;
    padding: 1.75em 0.75em;
  }

  @media (${$Mobile}) {
    width: 90%;
    padding: 1.5em 0.75em;
  }
`;

export const LanguageContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: auto;

  @media (${$Laptop}) {
    width: 600px;
    padding: 2em;
  }

  @media (${$TabletV}) {
    width: 80%;
    padding: 1.75em 0.75em;
  }

  @media (${$Mobile}) {
    width: 90%;
    padding: 1.5em 0.75em;
  }
`;

export const WidthContainer = styled('div')`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;

  @media (${$Desktop}) {
    max-width: 400px;
  }

  @media (${$Mobile}) {
    max-width: 95%;
  }
`;

export const LanguageDropdownContainer = styled('div')`
  display: block
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  background: white;
  margin-top: 20px;
  margin-bottom: 20px; 
  max-width: 240px;

  @media(${$Desktop}) {
    max-width: 240px;
  }

  @media(${$Mobile}) {
    max-width: 60%;
  }
`;

export const ContainerBottom = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ImgContainer = styled('div')`
  text-align: center;
`;

export const Logo = styled('img')`
  width: 300px;

  @media (${$Mobile}) {
    width: 200px;
  }

  @media (${$Mobile}) {
    width: 150px;
  }
`;

export const TextContainer = styled('div')`
  text-align: center;
  margin: 2em 0;

  @media (${$Mobile}) {
    margin: 1em 0;
  }
`;

export const Headline = styled('h1')`
  color: #000;
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  padding-bottom: 0.5em;

  @media (${$Desktop}) {
    font-size: 28px;
    line-height: 32px;
  }

  @media (${$Laptop}) {
    font-size: 24px;
    line-height: 26px;
  }

  @media (${$TabletV}) {
    font-size: 20px;
    line-height: 22px;
  }

  @media (${$Mobile}) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const Text = styled('p')`
  color: #000;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0;

  @media (${$Mobile}) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const SectionHeaderText = styled('p')`
  color: #000;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 0.5em;

  @media (${$Mobile}) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const TextSmall = styled('span')`
  position: relative;
  color: #000;
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  padding: 0 15px;
  background: white;
  z-index: 99;

  @media (${$Mobile}) {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const TextExtraSmall = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  @media (${$TabletV}) {
    font-size: 11px;
    line-height: 13px;
  }

  @media (${$Mobile}) {
    font-size: 9px;
    line-height: 11px;
  }
`;

export const BreakContainer = styled('div')`
  position: relative;
  text-align: center;
  margin: 2em 0;
`;

export const Break = styled('hr')`
  position: absolute;
  left: 0;
  right: 0;
  top: 4px;
  height: 0.1875em;
  border: none;
  background-color: #d2cfd0;
  z-index: 98;
`;

export const ButtonContainer = styled('div')`
  text-align: center;
  background-color: #000;
  margin-top: 0.5em;
  margin-bottom: 1.75em;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (${$TabletH}) {
    margin-bottom: 1.75em;
  }

  @media (${$TabletV}) {
    margin-bottom: 1.5em;
  }

  @media (${$Mobile}) {
    margin-bottom: 1em;
  }
`;

export const LinkElement = styled('a')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #255091;
  outline: none;
  border: none;
  width: 100%;
  padding: 15px 0;
  text-decoration: none;
  height: 32px;

  @media (${$Mobile}) {
    padding: 10px 0;
    height: 28px;
  }
`;

export const LinkElementDark = styled(LinkElement)`
  background-color: #0d1d3a;
`;

export const LinkText = styled('span')`
  color: #fff;
  font-size: 20px;
  line-height: 16px;
  font-weight: 700;

  @media (${$Mobile}) {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const LinkTextRed = styled(LinkText)`
  color: red;
`;

export const IDBold = styled(LinkText)`
  font-weight: 900;
`;

export const VersionText = styled(TextExtraSmall)`
  position: absolute;
  right: 0;
  top: 5px;
`;

export const LanguageOptionWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  background-color: ${props => props.theme.white};
  &:hover {
    background-color: ${props => props.theme.backgroundLight};
  }
  width: 100%;
`;

export const LanguageOptionDescription = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

export const LanguageOptionImage = styled.div`
  margin-right: 10px;
`;

export const LanguageOptionName = styled.div`
  color: ${props => props.theme.text};
`;

export const LanguageOptionPosition = styled.div`
  color: ${props => props.theme.textLightGray};
  margin-top: 2px;
`;
